import React from 'react'
import { useSelector } from 'react-redux'
import useCart from '@/public/hooks/use-cart';
import useAddToCart from '@/public/hooks/add-to-cart'
import { selectVerified } from '@/public/redux/recaptchaSlice';

export default function SubscribeButton({
  product,
  text = 'Subscribe',
  className = 'btn btn-subscription',
  children,
}) {
  const { cart } = useCart()
  const addToCart = useAddToCart(product, 1, true)
  const verified = useSelector(selectVerified)
  const existingItem = cart?.contents?.find((item) => item.data.sku === product.sku)

  const disabled = !verified || existingItem || /back_ordered/i.test(product.status)

  const handleClick = (e) => {
    e.stopPropagation()
    addToCart()
  }

  return (
    <button
      type="button"
      className={`${className}${existingItem ? ' disabled' : ''}`}
      disabled={disabled}
      onClick={(e) => handleClick(e)}
    >
      {children || text}
    </button>
  )
}
