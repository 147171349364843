import React from 'react'
import { useSelector } from 'react-redux'
import useCart from '@/public/hooks/use-cart';
import useAddToCart from '@/public/hooks/add-to-cart'
import { selectVerified } from '@/public/redux/recaptchaSlice'

export default function AddToCartButton({
  product,
  quantity = 1,
  text = 'Add to Cart',
  className = 'btn btn-primary',
  disabled = false,
  children,
}) {
  const verified = useSelector(selectVerified)
  const { cart } = useCart()
  const addToCart = useAddToCart(product, quantity)

  const existingItem = cart?.contents?.find((item) => item.data.sku === product.sku)

  const quantityLimited = existingItem && !product.quantifiable

  const shouldDisable = !verified
    || quantityLimited
    || disabled
    || /back_ordered/i.test(product.status)

  const handleClick = (e) => {
    e.stopPropagation()

    addToCart()
  }

  return (
    <button
      type="button"
      className={className}
      disabled={shouldDisable}
      onClick={(e) => handleClick(e)}
    >
      { children || text }
    </button>
  )
}
