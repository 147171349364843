import axios from 'axios';
import useCart from '@/public/hooks/use-cart';
import { accessToken } from '@/shared/helpers/CSRF'
import { useDispatch } from 'react-redux'
import { setOpen, setLoading } from '@/public/redux/cartSlice'

export default function useAddToCart(product, quantity, subscription = false) {
  const { cart, mutate } = useCart();
  const dispatch = useDispatch();
  const existingItem = cart?.contents?.find((item) => item.data.sku === product.sku)

  const createCart = () => {
    axios({
      method: 'post',
      url: '/api/cart',
      headers: { 'X-CSRF-Token': accessToken },
      data: {
        attributes: { user_id: null },
        cart: {
          contents: [
            {
              data: product,
              quantity,
              subscription,
            },
          ],
        },
      },
    })
      .then(({ data }) => {
        window.localStorage.setItem('cart', data.uuid)
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const addItemToCart = () => {
    axios({
      method: 'post',
      url: `/api/cart/${cart.uuid}/cart_items`,
      headers: { 'X-CSRF-Token': accessToken },
      data: { sku: product.sku, quantity, subscription },
    })
      .then(() => {
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const incrementCart = () => {
    axios({
      method: 'patch',
      url: `/api/cart/${cart.uuid}/cart_items/${product.id}`,
      headers: { 'X-CSRF-Token': accessToken },
      data: { quantity: existingItem.quantity + 1 },
    })
      .then(() => {
        mutate()
      })
      .then(() => dispatch(setLoading(false)))
  }

  const addToCart = () => {
    dispatch(setLoading(true))
    dispatch(setOpen(true))

    if (existingItem) {
      incrementCart()
    } else if (cart) {
      addItemToCart()
    } else {
      createCart()
    }
  }

  return addToCart
}
